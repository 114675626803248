<template>
  <ValidationProvider :vid="vid" :name="$attrs.name" :rules="rules">
    <a-form-item
      :align="'left'"
      v-bind="$attrs.formitem"
      slot-scope="{ errors, flags }"
      :validateStatus="resolveState({ errors, flags })"
      :help="errors[0]"
    >
      <a-month-picker
        v-bind="$attrs"
        :style="{ width: '100% !important', ...($attrs.customStyle || {}) }"
        v-model="innerValue"
      />
    </a-form-item>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  components: {
    ValidationProvider,
  },
  props: {
    vid: {
      type: String,
    },
    rules: {
      type: [Object, String],
      default: "",
    },
    // must be included in props
    value: {
      type: null,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    innerValue: "",
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
  methods: {
    resolveState({ errors, flags }) {
      if (errors[0]) {
        return "error";
      }

      if (flags.pending) {
        return "validating";
      }

      if (flags.valid) {
        return "success";
      }

      return "";
    },
  },
};
</script>
