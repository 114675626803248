<template>
  <div class="commissions-report-page">
    <a-row type="flex" justify="space-between">
      <a-col :lg="10" :md="10" :xs="24">
        <h2 class="list-title">
          <font-awesome-icon
            class="anticon list-icon"
            :icon="['fas', 'users']"
          />
          <span>{{ labels.title }}</span>
        </h2>
        <p class="list-subtitle">{{ labels.subtitle }}</p>
      </a-col>
    </a-row>
    <a-spin :spinning="loading" :tip="labels.table.loading">
      <a-row>
        <a-col :span="24" v-if="!generated">
          <ValidationObserver ref="observer">
            <a-form
              :model="form"
              :label-width="80"
              slot-scope="{ validate }"
              @submit.prevent="validate().then(handleGenerateReport)"
            >
              <a-card class="commissions-report-card">
                <a-row
                  type="flex"
                  justify="start"
                  :style="{ alignItems: 'center' }"
                  :gutter="16"
                >
                  <!-- Date -->
                  <a-col :sm="24" :md="4">
                    <MonthPicker
                      v-model="form.date"
                      :vid="labels.form.date.label"
                      :name="labels.form.date.label"
                      :formitem="{ label: labels.form.date.label }"
                      :placeholder="labels.form.date.placeholder"
                      :format="dateFormat"
                      rules="required"
                    />
                  </a-col>
                  <!-- User -->
                  <a-col :sm="24" :md="4">
                    <SelectPagination
                      v-model="form.user"
                      :vid="labels.form.user.label"
                      :label="labels.form.user.label"
                      :placeholder="labels.form.user.placeholder"
                      :fetchService="fetchUsers"
                      :labelKey="'fullname'"
                      :valueKey="'id'"
                      :allowClear="true"
                      :disabledDefaultFetchOptions="false"
                    />
                  </a-col>
                  <!-- Buttons -->
                  <a-col :sm="24" :md="4">
                    <a-button
                      type="primary"
                      ghost
                      :loading="loading"
                      @click="validate().then(handleGenerateReport)"
                      :style="{ marginTop: '15px' }"
                    >
                      <span>{{ labels.form.action.generate }}</span>
                    </a-button>
                  </a-col>
                </a-row>
              </a-card>
            </a-form>
          </ValidationObserver>
        </a-col>
        <a-col :span="24" v-if="generated">
          <a-row
            type="flex"
            justify="space-between"
            :style="{
              alignItems: 'center',
              marginTop: '15px',
              marginBottom: '15px',
            }"
            :gutter="16"
          >
            <a-col>
              <a-button
                type="primary"
                ghost
                :loading="loading"
                @click="handleBack"
                icon="arrow-left"
              >
                <span>{{ labels.form.action.goBack }}</span>
              </a-button>
            </a-col>
            <a-col v-if="canPaidCommissions">
              <a-button
                type="primary"
                :loading="loading"
                @click="handlePaidCommissions"
                :disabled="selected.length === 0"
              >
                <span
                  >{{ labels.form.action.pay
                  }}{{ !!selected.length ? ` (${selected.length})` : "" }}</span
                >
              </a-button>
            </a-col>
          </a-row>
          <StandardTable
            ref="standardTable"
            :rowKey="rowKey"
            :columns="columns"
            :locale="locale"
            :data="dataSource"
            :scroll="{ x: true }"
            :paginate="false"
            :selection="true"
            :rowSelection="rowSelection"
            :onSelectRow="onSelectRow"
            :selectedRowsKey="selectedRowsKey"
          />
          <a-card class="summary-card" :bodyStyle="{ padding: '5px 20px' }">
            <a-row
              type="flex"
              justify="end"
              :style="{
                marginTop: '15px',
                marginBottom: '15px',
              }"
              :gutter="40"
            >
              <a-col
                :style="{
                  justifySelf: 'flex-start',
                  alignSelf: 'center',
                  flex: 'auto',
                }"
              >
                <h2 class="total-label">{{ labels.table.summary.totals }}</h2>
              </a-col>
              <a-col>
                <a-statistic
                  :title="labels.table.summary.total"
                  :value="total"
                  :precision="2"
                  :prefix="'RD$ '"
                  :valueStyle="{ color: '#218838' }"
                />
              </a-col>
              <a-col>
                <a-statistic
                  :title="labels.table.summary.averageCommissionPercentage"
                  :value="averageCommissionPercentage"
                  :precision="2"
                  :suffix="'%'"
                  :valueStyle="{ color: '#0069d9' }"
                />
              </a-col>
              <a-col>
                <a-statistic
                  :title="labels.table.summary.totalCommissions"
                  :value="totalCommissions"
                  :precision="2"
                  :prefix="'RD$ '"
                  :valueStyle="{ color: '#e0a800' }"
                />
              </a-col>
              <a-col>
                <a-statistic
                  :title="labels.table.summary.totalPaid"
                  :value="totalPaid"
                  :precision="2"
                  :prefix="'RD$ '"
                  :valueStyle="{ color: '#218838' }"
                />
              </a-col>
              <a-col>
                <a-statistic
                  :title="labels.table.summary.totalPending"
                  :value="totalPending"
                  :precision="2"
                  :prefix="'RD$ '"
                  :valueStyle="{ color: '#c82333' }"
                />
              </a-col>
            </a-row>
          </a-card>
        </a-col>
      </a-row>
    </a-spin>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import labels from "@/utils/labels";
import {
  DATE_FORMAT,
  adminPageTitle,
  hasRoles,
  roles,
} from "../../../utils/utils";
import StandardTable from "../../../components/core/table/StandardTable.vue";
import MonthPicker from "@/components/core/VeeValidateForm/MonthPicker.vue";
import SelectPagination from "@/components/core/SelectPagination/index.vue";
import { mapActions, mapGetters } from "vuex";
import accounting from "accounting";
import _ from "lodash";
import moment from "moment";
const initialState = {
  date: moment(),
  user: [],
};
export default {
  components: {
    StandardTable,
    ValidationObserver,
    MonthPicker,
    SelectPagination,
  },
  name: "Report",
  metaInfo: {
    title: adminPageTitle(labels.commissions.title),
  },
  data() {
    return {
      labels: labels.commissions,
      search: "",
      loading: false,
      data: [],
      form: _.cloneDeep(initialState),
      generated: false,
      selected: [],
      selectedRowsKey: [],
    };
  },
  methods: {
    ...mapActions("users", ["fetchUsers"]),
    ...mapActions("commissions", ["generateReport", "paidCommissions"]),
    rowKey(_, index) {
      return index;
    },
    onSelectRow(selection) {
      this.selected = selection;
    },
    async handleGenerateReport() {
      try {
        const { date, user } = _.cloneDeep(this.form);
        const params = {
          date: moment(date).format(this.serverFormat),
          ...(user && { user: user.id }),
        };
        this.loading = true;
        const { data: response } = await this.generateReport(params);
        const { data } = response;
        this.data = data;
        this.generated = true;
      } catch (error) {
        this.$message.error(
          error?.response?.data?.message ||
            error?.response?.message ||
            error.message
        );
      } finally {
        this.loading = false;
      }
    },
    handlePaidCommissions() {
      if (this.selected.length === 0) {
        this.$message.error(this.labels.noSelectionError);
        return;
      }
      this.$confirm({
        title: this.labels.confirm.title,
        content: this.labels.confirm.subtitle,
        okText: this.labels.confirm.confirmButtonText,
        okType: "primary",
        icon: "check-circle",
        cancelText: this.labels.confirm.cancelButtonText,
        onOk: async () => {
          try {
            this.loading = true;
            const { data, message } = await this.paidCommissions(this.selected);
            const dataSource = _.cloneDeep(this.data).map((item) => {
              const commission = data.find(
                (commission) =>
                  !!commission.id &&
                  commission?.month === item?.month &&
                  commission?.year === item?.year &&
                  commission?.user?.id === item?.user?.id &&
                  commission?.order?.id === item?.order?.id
              );
              if (commission) {
                Object.assign(item, commission);
              }
              return item;
            });
            this.data = dataSource;
            this.$message.success(
              this.labels.payGeneratedSucessfully || message
            );
            this.selected = [];
          } catch (error) {
            console.error(error);
            this.$message.error(
              error?.response?.data?.message ||
                error?.response?.message ||
                error.message ||
                this.labels.errorGeneratingPay
            );
          } finally {
            this.loading = false;
          }
        },
      });
    },
    handleBack() {
      Object.assign(this.form, _.cloneDeep(initialState));
      this.generated = false;
      this.selected = [];
      this.data = [];
    },
    findIndexByOptions({ month, year, userId, orderId }) {
      return this.dataSource.findIndex((item) => {
        return (
          item.month === month &&
          item.year === year &&
          item.user.id === userId &&
          item.order.id === orderId
        );
      });
    },
  },
  computed: {
    ...mapGetters("adminAuth", ["getLoggedUser"]),
    locale() {
      return {
        emptyText: this.labels.table.emptyText.replace(
          "{date}",
          this.form.date ? moment(this.form.date).format(this.dateFormat) : ""
        ),
      };
    },
    canPaidCommissions() {
      const { accessRoles } = this.getLoggedUser;
      return hasRoles(accessRoles, [roles.admin.ROLE_COMMISSIONS_PROCESS]);
    },
    rowSelection() {
      return {
        getCheckboxProps: (record) => {
          return {
            props: {
              disabled: !!record.paid,
            },
          };
        },
      };
    },
    columns() {
      return [
        {
          title: this.labels.table.columns.date,
          dataIndex: "date",
          key: "date",
          customRender: (text) => {
            return text
              ? this.$moment(text).format(DATE_FORMAT.MOMENT_DATE)
              : "-";
          },
        },
        {
          title: this.labels.table.columns.type.title,
          dataIndex: "type",
          key: "type",
          customRender: (text) => {
            return this.labels.table.columns.type[text] || "-";
          },
        },
        {
          title: this.labels.table.columns.user,
          dataIndex: "user",
          key: "user",
          customRender: (text) => {
            return `${text.firstname} ${text.lastname || ""}`.trim();
          },
        },
        // {
        //   title: this.labels.table.columns.month,
        //   dataIndex: "month",
        //   key: "month",
        //   customRender: (text) => {
        //     return text
        //       ? this.$options.filters.ucfirst(
        //           moment(text?.toString()?.padStart(2, "0"), "MM")?.format(
        //             "MMMM"
        //           ) || ""
        //         )
        //       : "-";
        //   },
        // },
        // {
        //   title: this.labels.table.columns.year,
        //   dataIndex: "year",
        //   key: "year",
        //   customRender: (text) => {
        //     return text || "-";
        //   },
        // },
        {
          title: this.labels.table.columns.total,
          dataIndex: "total",
          key: "total",
          customRender: (text) => {
            return text ? accounting.formatNumber(text, 2, ",", ".") : "N/A";
          },
        },
        {
          title: this.labels.table.columns.percentage,
          dataIndex: "percentage",
          key: "percentage",
          customRender: (text) => {
            return text ? `${text}%` : "N/A";
          },
        },
        {
          title: this.labels.table.columns.amount,
          dataIndex: "amount",
          key: "amount",
          customRender: (text) => {
            return text ? accounting.formatNumber(text, 2, ",", ".") : "N/A";
          },
        },
        {
          title: this.labels.table.columns.paid,
          dataIndex: "paid",
          key: "paid",
          customRender: (text) => {
            return `${text ? "Sí" : "No"}`;
          },
        },
        {
          title: this.labels.table.columns.paidAt,
          dataIndex: "paid_at",
          key: "paid_at",
          customRender: (text) => {
            return text
              ? this.$moment(text).format("DD/MM/YYYY H:mm:ss A")
              : "-";
          },
        },
        {
          title: this.labels.table.columns.createdBy,
          dataIndex: "created_by",
          key: "created_by",
          customRender: (text) => {
            return `${text?.name || "-"}`;
          },
        },
      ];
    },
    dateFormat() {
      return "MMMM YYYY";
    },
    serverFormat() {
      return "YYYY-MM";
    },
    dataSource() {
      return this.data;
    },
    total() {
      return this.dataSource.reduce(
        (acc, commission) => acc + commission.total,
        0
      );
    },
    totalCommissions() {
      return this.dataSource.reduce(
        (acc, commission) => acc + commission.amount,
        0
      );
    },
    averageCommissionPercentage() {
      const average = _.mean(
        this.dataSource.map((row) => row?.percentage || 0)
      );
      return isNaN(average) ? 0 : average;
    },
    totalPaid() {
      return this.dataSource.reduce(
        (acc, commission) => acc + (commission.paid ? commission.amount : 0),
        0
      );
    },
    totalPending() {
      return this.dataSource.reduce(
        (acc, commission) => acc + (!commission.paid ? commission.amount : 0),
        0
      );
    },
  },
  watch: {
    selected: {
      handler(selection = []) {
        this.selectedRowsKey = selection.map(({ month, year, user, order }) =>
          this.findIndexByOptions({
            month,
            year,
            userId: user.id,
            orderId: order.id,
          })
        );
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss">
@import "../../../assets/scss/variable";
.commissions-report-page {
  .commissions-report-card {
    margin-top: 15px;
  }
  .summary-card {
    margin-top: 30px;
  }
  .list {
    &-title {
      margin-bottom: 10px;
      .list-icon {
        margin-right: 10px;
      }
    }
    &-subtitle {
      margin-bottom: 10px;
    }
  }
  .add-button {
    color: color(primary);
    border-color: color(primary);
  }
  .action-buttons {
    display: flex;
    justify-content: space-between;
    .action-button-edit {
      color: color(primary);
    }
    .action-button-delete {
      color: color(danger);
    }
  }
  .ant-statistic-title {
    font-size: 16px;
  }
  .ant-calendar-picker-input.ant-input {
    text-transform: capitalize !important;
  }
}
</style>
